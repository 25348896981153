<template>
	<div id="accountLevel" class="el-content">
		<a-button class="mb12" type="primary" v-has="{action:'wxapp_account_level_edit',plat:0}" @click="showAddLevel(0)"><i class="ri-add-line"></i>新增会员等级</a-button>
		<a-table row-key="id" :pagination="false" :data-source="info.list" :columns="[
			{title:'ID',dataIndex:'id'},
			{title:'等级',dataIndex:'level'},
			{title:'等级名称',dataIndex:'level_name'},
			{title:'折扣(%)',dataIndex:'discount'},
			{title:'升级条件',dataIndex:'up_price'},
			{title:'状态',dataIndex:'level_status',slots:{customRender:'level_status'}},
			{title:'最后更新时间',dataIndex:'create_time'},
			{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
		]" class="mt12">
			<template #level_status="{record}">
				<a-tag :color="record.level_status == 1 ?'#00CC66':'#999'">{{ record.level_status == 1 ?'启用':'禁用'}}</a-tag>
			</template>
			<template #action="{record}">
				<a-space v-if="record.level!=0">
					<kd-button type="primary" title="编辑" icon="ri-edit-line" v-has="{action:'wxapp_account_level_edit'}" @click="showAddLevel(record)"></kd-button>
					<kd-button type="danger" title="删除" icon="ri-delete-bin-5-line" v-has="{action:'wxapp_account_level_del'}" @click="deleteAccountLevel(record.id)"></kd-button>
				</a-space>
			</template>
		</a-table>
		<div class="pager">
			<a-pagination show-size-changer
				:default-current="info.page"
				:total="info.count"
				@showSizeChange="(p,e)=>{getAccountLevelList(info.page,e)}"
				@change="(e)=>{getAccountLevelList(e,info.limit)}"
			/>
		</div>
		
		<a-modal title="添加会员等级" :visible="show.add" @ok="submitSave" @cancel="show.add = false" width="700px">
			<a-form :label-col="{span:4}" :wrapper-col="{span:16}">
				<a-form-item name="level">
					<template #label>
						<span>会员等级</span>
						<a-tooltip title="数字越大，等级越高">
							<i class="ri-information-fill ri-top"></i>
						</a-tooltip>
					</template>
					<a-select v-model:value="form.level" placeholder="请选择">
					    <a-select-option
							v-for="item in 100"
							:key="item"
							:value="item">
							{{ item}}
					    </a-select-option>
					  </a-select>
				</a-form-item>
				<a-form-item label="等级名称" required>
					<a-input v-model:value="form.level_name"></a-input>
				</a-form-item>
				<a-form-item label="升级条件" required>
					<a-input addon-before="完成订单满" addon-after="元"  v-model:value="form.up_price" placeholder="请输入内容"/>
				</a-form-item>
				<a-form-item required>
					<template #label>
						<span>等级折扣</span>
						<a-tooltip title="折扣计算方式:原价 x 折扣% = 现价 例子:原价100元的商品,打6折(6折此处填写60) 则用户只需要付60元" placement="top">
							<i class="ri-information-fill ri-top"></i>
						</a-tooltip>
					</template>
					<a-input v-model:value="form.discount" addon-after="%"></a-input>
				</a-form-item>
				<a-form-item label="等级状态" >
					<a-radio-group v-model:value="form.level_status">
						<a-radio :value="1">启用</a-radio>
						<a-radio :value="0">禁用</a-radio>
					</a-radio-group>
				</a-form-item>
			</a-form>
		</a-modal>
	</div>
</template>

<script>
import userModel from "@/api/user.js"
import common from "@/api/common.js"
import { reactive, toRefs } from 'vue'
export default {
	setup(){
		const _d = reactive({
			info:{
				list:[],
				page:1,
				count:0,
				limit:10,
			},
			show:{ add:false, },
			form:null,
		})
		getAccountLevelList(1,_d.info.limit)
		function getAccountLevelList(page,limit){
			userModel.getAccountLevel(page,limit,res=>_d.info = {limit,...res})
		}
		function showAddLevel(data){
			_d.form = {
				id:data.id ? data.id :0,
				level:data.level ? data.level :"",
				discount:data.discount ? data.discount :"",
				up_price:data.up_price ? data.up_price :"",
				level_name:data.level_name ? data.level_name :"",
				level_status:data.level_status ? data.level_status :0,
			}
			_d.show.add = true
		}
		const deleteAccountLevel = (id)=>common.deleteDataList(id,14,"确认删除该会员等级吗").then(()=>{
			getAccountLevelList(_d.info.page,_d.info.limit)
		})
		const submitSave = ()=>userModel.addOrEditAccountLevel(_d.form,()=>{
			_d.show.add = false
			getAccountLevelList(_d.info.page,_d.info.limit)
		})
		return{
			...toRefs(_d),
			getAccountLevelList,
			deleteAccountLevel,
			showAddLevel,
			submitSave
		}
	},
}
</script>

<style>
	
</style>
